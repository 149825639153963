'use client';

import { Homepage } from '@into-discovery/browsewebshared';
import { useLocale } from 'next-intl';
import { usePathname, useRouter } from '@/navigation';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { getCurrentSafeLanguage } from '@/i18n-config';
import { IHomeProps } from '@/models/homepage/IHomeProps';

type Props = {
  pageProps: IHomeProps;
};

export default function HomeProvider({ pageProps }: Props) {
  const currentLocale = useLocale();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const router = useRouter();
  useEffect(() => {
    if (window.location.host.includes('search')) {
      router.replace(`/search`, {
        locale: getCurrentSafeLanguage(currentLocale),
      });
    }
  }, [currentLocale, router]);

  return (
    <div id="homepage" className="my-n3 my-lg-n4">
      <Homepage
        content={pageProps?.content}
        currentLocale={currentLocale}
        router={useRouter()}
        applicationSettings={pageProps?.applicationSettings}
        searchParams={searchParams}
        pathname={pathname}
        applicationURL={process.env.NEXT_PUBLIC_INTO_STUDY_URL}
      />
    </div>
  );
}
